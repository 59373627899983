import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/lang/index.js'
// silent 将停止所有的Vuetify日志和警告
// Vuetify.config.silent = true
// Translation provided by Vuetify (javascript)

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
