import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/lang/index'
import global from "@/global";
import request from './api/index'
import {Image} from 'element-ui';

import './common/css/index.css'
// 引入iconfont图标
import "../src/assets/font_icon/iconfont.css"

Vue.config.productionTip = false

Vue.prototype.$globalData = global
Vue.prototype.$request = request

Vue.use(Image);

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
