import axios from 'axios'
import globalData from "../global";

const request = axios.create({
  // 统一设置URL前缀
  baseURL: `${globalData.serverIp}`,
  // 请求最长响应时间
  timeout: 5000,
})

// 请求拦截器
request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json';
  return config
}, error => {
  return Promise.reject(error)
});

// 响应拦截器
request.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
});

export default request;