import VueI18n from 'vue-i18n'
import Vue from "vue";
import en from '@/lang/en'
import zhHans from "@/lang/zhHans";

Vue.use(VueI18n);

const messages = {
  en: en,
  zh: zhHans
}

export default new VueI18n({
  locale: 'zh', // 默认中文简体
  messages,
  silentTranslationWarn: true
})
