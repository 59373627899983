import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/lang/index'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject)
  }
  return originalReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}

const routes = [
  {
    path: '/detail.php*',
    name: 'series',
    redirect: '/zh/product',
  },
  {
    path: '/showLinkConvert',
    name: 'link',
    component: () => import('../views/LinkList'),
  },
  {
    path: '',
    name: 'Manager',
    redirect: '/:lang/',
    component: () => import('../views/Manager'),
    children: [
      {
        path: '/404',
        name: 'NotFound',
        component: () => import('../views/404.vue'),
      }, {
        path: '/:lang/search',
        name: 'search',
        props: route => ({query: route.query.words}),
        component: () => import('../views/Search.vue'),
      }, {
        path: '/:lang/',
        name: 'home',
        props: true,
        component: () => import('../views/Home.vue'),
        meta: {
          keepAlive: true
        },
      }, {
        path: '/:lang/product',
        name: 'product',
        props: true,
        meta: {
          keepAlive: true,
          showFather: true,
        },
        component: () => import('../views/product/index'),
        children: [
          {
            path: 'series/:seriesId',
            name: 'productSeries',
            props: true,
            component: () => import('../views/product/series'),
            meta: {
              keepAlive: true,
              showFather: false
            }
          },
          {
            path: ':productId',
            name: 'productDetail',
            props: true,
            component: () => import('../views/product/detail'),
            meta: {
              showFather: false
            },
          }],
      },
      {
        path: '/:lang/case',
        name: 'case',
        props: true,
        component: () => import('../views/case/index'),
        meta: {
          showFather: true
        },
        children: [
          {
            path: ':caseId',
            name: 'caseDetail',
            props: true,
            component: () => import('../views/case/detail'),
            meta: {
              showFather: false
            }
          }
        ]
      }, {
        path: '/:lang/support/series/:seriesId',
        name: 'supportSeries',
        props: true,
        component: () => import('../views/support/series'),
        meta: {
          keepAlive: true,
        },
      }, {
        path: '/:lang/support/:supportId',
        name: 'support',
        props: true,
        component: () => import('../views/support/detail'),
      }, {
        path: '/:lang/news',
        name: 'news',
        props: true,
        component: () => import('../views/news/index'),
        meta: {
          showFather: true
        },
        children: [
          {
            path: ':newsId',
            name: 'newsDetail',
            props: true,
            component: () => import('../views/news/detail'),
            meta: {
              showFather: false
            }
          }
        ]
      }, {
        path: '/:lang/about/:aboutId',
        name: 'about',
        props: true,
        component: () => import('../views/about/index')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (['zh', 'en'].indexOf(to.params.lang) === -1) {
    if (['/404', '/showLinkConvert'].indexOf(to.path) !== -1) {
      return next()
    } else {
      return next('/' + i18n.locale + '/')
    }
  } else if (to.params.lang !== i18n.locale) {
    i18n.locale = to.params.lang
  }
  if (!to.matched.length) {
    if (to.path === '/') {
      return next('/' + i18n.locale + '/')
    }
    return next('/404')
  }
  next()
})

export default router
